.user-list-container {
    padding: 20px;
}

.user-list-table {
    width: 100%;
    border-collapse: collapse;
}

.user-list-table th, .user-list-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.user-list-table th {
    background-color: #f2f2f2;
    text-align: left;
}